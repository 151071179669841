<template>
	<div style="width: 1200px; margin: auto">
		<div style="margin-bottom: 10px;" @click="back">返回</div>
		<div class="header" v-if="info">
			<div class="img">
				{{ info.name[0] }}
			</div>
			<div class="right">
				<span style="font-weight: bold; font-size: 20px; color: #222222">{{
          info.name
        }}</span>
				<div class="info">
					<div>
						<label>法定代表人：</label><span>{{ info.oper_name }}</span>
					</div>
					<div>
						<label>统一社会信用代码：</label><span>{{ info.credit_no }}</span>
					</div>
					<div>
						<label>成立日期：</label><span>{{ info.start_date }}</span>
					</div>
					<div>
						<label>联系方式：</label><span v-if="!info.phoneShow" @click="checkNum"
							style="color: #6ec1e8">查看电话号码</span><span v-else>{{ info.phone }}</span>
					</div>
					<!-- <div>
                        <label>地址：</label><span>{{ info.matchItems }}</span>
                    </div> -->
				</div>
			</div>
		</div>
		<div class="mian">
			<div class="tit">
				<span>企业详细信息查询</span>
				<i v-show="unfold" class="el-icon-arrow-down" @click="unfold = false"></i>
				<i v-show="!unfold" class="el-icon-arrow-up" @click="unfold = true"></i>
			</div>
			<div v-show="unfold">
				<div class="item" v-for="item in category" :key="item.id">
					<div class="title">
						<label>{{ item.title }}</label><span @click="selectAll('工商信息*全选',item)">全选</span>
					</div>
					<div class="content">
						<span v-for="val in item.child" :key="val.id" @click="check(item, val)">{{ val.title }}</span>
					</div>
				</div>
				<!-- <div class="item">
                    <div class="title">
                        <label>私发诉讼</label><span>全选</span>
                    </div>
                    <div class="content">
                        <span v-for="item in 30">小类别1</span>
                    </div>
                </div>
                <div class="item">
                    <div class="title">
                        <label>知识产权</label><span>全选</span>
                    </div>
                    <div class="content">
                        <span v-for="item in 30">小类别1</span>
                    </div>
                </div>
                <div class="item">
                    <div class="title">
                        <label>企业关系</label><span>全选</span>
                    </div>
                    <div class="content">
                        <span v-for="item in 30">小类别1</span>
                    </div>
                </div> -->
			</div>
			<div v-show="selectAllPay">
				<div
					style="width: 1200px;height: 60px;background: #f6f6f6;border-left: 6px solid #e40012;line-height: 60px;">
					<h3 style="margin-left: 14px">企业概况</h3>
				</div>
				<div class="table">
					<h5 style="margin: 30px 0 20px 0;font-size: 16px;font-weight: 400;color: #222222;">
						工商信息
					</h5>
					<div class="tbody">
						<div class="tr">
							<div class="td">法定代表人</div>
							<div class="td">{{ info.oper_name }}</div>
						</div>
						<div class="tr">
							<div class="td">注册资本</div>
							<div class="td">{{ info.reg_capi_desc }}</div>
						</div>
						<div class="tr">
							<div class="td">统一社会信用代码</div>
							<div class="td">{{ info.credit_no }}</div>
						</div>
						<div class="tr">
							<div class="td">工商注册号</div>
							<div class="td">{{ info.reg_no }}</div>
						</div>
						<div class="tr">
							<div class="td">所属行业</div>
							<div class="td"></div>
						</div>
						<div class="tr">
							<div class="td">企业类型</div>
							<div class="td"></div>
						</div>
						<div class="tr">
							<div class="td">注册地址</div>
							<div class="td">{{ info.address }}</div>
						</div>
						<div class="tr">
							<div class="td">经营范围</div>
							<div class="td"></div>
						</div>
					</div>
				</div>
				<div class="table2">
					<h5 style="
              margin: 30px 0 20px 0;
              font-size: 16px;
              font-weight: 400;
              color: #222222;
            ">
						发起人/股东信息
					</h5>
					<div class="tbody">
						<div class="tr">
							<div class="td">序号</div>
							<div class="td">发起人/股东</div>
							<div class="td">出股比例</div>
							<div class="td">认缴出金额</div>
							<div class="td">实缴出金额</div>
						</div>
						<div class="tr" style="height: 62px" v-for="(item, index) in 2" :key="index">
							<div class="td"></div>
							<div class="td"></div>
							<div class="td"></div>
							<div class="td"></div>
							<div class="td"></div>
						</div>
					</div>
				</div>
				<div class="table2">
					<h5 style="
              margin: 30px 0 20px 0;
              font-size: 16px;
              font-weight: 400;
              color: #222222;
            ">
						主要人员
					</h5>
					<div class="tbody">
						<div class="tr">
							<div class="td">序号</div>
							<div class="td">发起人/股东</div>
							<div class="td">职务</div>
							<div class="td">持股比例</div>
						</div>
						<div class="tr" style="height: 62px" v-for="(item, index) in 2" :key="index">
							<div class="td"></div>
							<div class="td"></div>
							<div class="td"></div>
							<div class="td"></div>
						</div>
					</div>
				</div>
				<div class="table2">
					<h5 style="
              margin: 30px 0 20px 0;
              font-size: 16px;
              font-weight: 400;
              color: #222222;
            ">
						主要人员
					</h5>
					<div class="tbody">
						<div class="tr">
							<div class="td">序号</div>
							<div class="td">发起人/股东</div>
							<div class="td">职务</div>
							<div class="td">持股比例</div>
						</div>
						<div class="tr" style="height: 62px" v-for="(item, index) in 2" :key="index">
							<div class="td"></div>
							<div class="td"></div>
							<div class="td"></div>
							<div class="td"></div>
						</div>
					</div>
				</div>
				<!-- 分页器 -->
				<!-- <div class="pagination">
                    <el-pagination background layout="prev, pager, next" :page-size="24" :total="240">
                    </el-pagination>
                    <div class="total">共8000件/24页</div>
                    <div class="jump">
                        跳转到
                        <input type="number">
                        页
                    </div>
                    <el-button style="background:#2EA7E0;color:#fff;width:108px;height:50px;font-size:20px">前往</el-button>
                </div> -->
			</div>
		</div>

		<!-- 弹出框 -->
		<el-dialog title="" :visible.sync="dialogVisible" width="1200px" :before-close="handleDialogClose">
			<div class="dialog-title">
				<img src="../../assets/images/square/ts.png" alt=""
					style="margin-right: 5px; width: 25px; height: 25px" />
				正在查询<span style="color: #e40012">{{ info.matchItems }}</span>的{{ condition }}
			</div>
			<div style="width: 1140px; height: 0px; border: 1px solid #eeeeee"></div>
			<div class="dialog-body">
				<div style="display: flex">
					<label>已筛条件</label><span>{{ condition }}</span>
				</div>
				<div style="margin-top: 30px">
					<label for="">购买金额</label><span style="color: #e40012">￥{{ needMoney }}</span>
				</div>
				<div style="color: #00a71c; margin-top: 70px">
					注：已跟你确认并绑定你的钱包
				</div>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button class="dialog-btn" @click="confrimPay">确认支付</el-button>
				<el-button class="dialog-btn" style="border: 1px solid #e40012; background: #ffffff; color: #e40012"
					type="primary" @click="dialogVisible = false">取消支付</el-button>
			</span>
		</el-dialog>

		<!-- 查询内容 -->
		<el-dialog title="" :visible.sync="dialogtext" width="1200px" :before-close="handleDialogClose">
			<div class="dialog-title">
				<img src="../../assets/images/square/ts.png" alt=""
					style="margin-right: 5px; width: 25px; height: 25px" />
				正在查询<span style="color: #e40012">{{ info.matchItems }}</span>的{{ condition }}
			</div>
			<!-- <div style="width: 1140px; height: 0px; border: 1px solid #eeeeee"></div> -->
			<div class="dialog-body">
				<div class="header" v-if="info">
					<div class="img">
						{{ info.name[0] }}
					</div>
					<div class="right">
						<span style="font-weight: bold; font-size: 20px; color: #222222">{{info.name}}</span>
						<div class="info">
							<div>
								<label>法定代表人：</label><span>{{ info.oper_name }}</span>
							</div>
							<div>
								<label>统一社会信用代码：</label><span>{{ info.credit_no }}</span>
							</div>
							<div>
								<label>成立日期：</label><span>{{ info.start_date }}</span>
							</div>
							<div>
								<!-- <span v-if="!info.phoneShow" @click="checkNum" style="color: #6ec1e8">查看电话号码</span> -->
								<label>联系方式：</label><span>{{ info.phone }}</span>
							</div>
							<div>
								<label>地址：</label><span>{{ info.address }}</span>
							</div>
							<div v-if="condition=='企业联系方式'">
								<label>邮箱：</label><span>{{ list.email }}</span>
							</div>
						</div>
					</div>
				</div>
				<div class="details">
					<div class="title">{{condition}}</div>
					<div class="" v-if="lestName.includes(condition)">
						<div class="meeting" v-if="typeName[0]=='工商照面'">
							<div class="row" style="display:flex">
								<div class="td" style="flex:1.5">法人代表</div>
								<div class="td" style="flex:3">{{list.operName || ''}}</div>
								<div class="td" style="flex:1.5">经营状态</div>
								<div class="td" style="flex:3;border:none">{{list.new_status}}</div>
							</div>
							<div class="row" style="display:flex">
								<div class="td" style="flex:1.5">注册资本</div>
								<div class="td" style="flex:3">{{list.registCapi}}</div>
								<div class="td" style="flex:1.5">实缴资本</div>
								<div class="td" style="flex:3;border:none">{{list.actualCapi}}</div>
							</div>
							<div class="row" style="display:flex">
								<div class="td" style="flex:1.5">统一社会信用代码</div>
								<div class="td" style="flex:3">{{list.creditNo}}</div>
								<div class="td" style="flex:1.5">纳税人识别号</div>
								<div class="td" style="flex:3;border:none"></div>
							</div>
							<div class="row" style="display:flex">
								<div class="td" style="flex:1.5">工商注册号</div>
								<div class="td" style="flex:3">{{list.regNo}}</div>
								<div class="td" style="flex:1.5">组织机构代码</div>
								<div class="td" style="flex:3;border:none">{{list.orgNo}}</div>
							</div>
							<div class="row" style="display:flex">
								<div class="td" style="flex:1.5">进出口企业代码</div>
								<div class="td" style="flex:3"></div>
								<div class="td" style="flex:1.5">海关注册编码</div>
								<div class="td" style="flex:3;border:none"></div>
							</div>
							<div class="row" style="display:flex">
								<div class="td" style="flex:1.5">英文名</div>
								<div class="td" style="flex:3">{{list.format_name}}</div>
								<div class="td" style="flex:1.5">历史名称</div>
								<div class="td" style="flex:3;border:none">{{list.historyNames}}</div>
							</div>
							<div class="row" style="display:flex">
								<div class="td" style="flex:1.5">所属行业</div>
								<div class="td" style="flex:3"></div>
								<div class="td" style="flex:1.5">成立日期</div>
								<div class="td" style="flex:3;border:none">{{list.startDate}}</div>
							</div>
							<div class="row" style="display:flex">
								<div class="td" style="flex:1.5">企业类型</div>
								<div class="td" style="flex:3">{{list.econKind}}</div>
								<div class="td" style="flex:1.5">营业期限</div>
								<div class="td" style="flex:3;border:none">{{list.termEnd}}</div>
							</div>
							<div class="row" style="display:flex">
								<div class="td" style="flex:1.5">登记机关</div>
								<div class="td" style="flex:3">{{list.belongOrg}}</div>
								<div class="td" style="flex:1.5">核准日期</div>
								<div class="td" style="flex:3;border:none">{{list.checkDate}}</div>
							</div>
							<div class="row" style="display:flex">
								<div class="td" style="flex:1">注册地址</div>
								<div class="td" style="flex:5;border:none">{{list.address}}</div>
							</div>
							<div class="row" style="display:flex; border-bottom:none">
								<div class="td" style="flex:1">经营范围</div>
								<div class="td" style="flex:5;line-height:20px;border:none">{{list.scope}}</div>
							</div>
						</div>
						<div class="Trademark" v-if="typeName[0]=='商标列表'">
							<el-table :data="typeList.items" border style="width: 100%">
								<el-table-column fixed type="index" label="序号" width="150">
								</el-table-column>
								<el-table-column prop="" label="商标">
									<template slot-scope="scope">
										　　　　<img :src="scope.row.image_name" width="64" height="40" class="head_pic" />
										　　</template>
								</el-table-column>
								<el-table-column prop="company" label="企业名称">
								</el-table-column>
								<el-table-column prop="reg_number" label="注册号">
								</el-table-column>
								<el-table-column prop="apply_date" label="申请日期">
								</el-table-column>
								<el-table-column prop="type_name" label="商标类型">
								</el-table-column>
								<el-table-column fixed="right" label="操作" width="150">
									<template slot-scope="scope">
										<el-button @click="handleClick(scope.row)" type="text" size="small">详情
										</el-button>
									</template>
								</el-table-column>
							</el-table>
						</div>
						<div class="Trademark" v-if="typeName[0]=='资质信息'">
							<el-table :data="typeList.items" border style="width: 100%">
								<el-table-column fixed type="index" label="序号">
								</el-table-column>
								<el-table-column prop="type" label="证书类型">
								</el-table-column>
								<el-table-column prop="num" label="证书编号">
								</el-table-column>
								<el-table-column prop="status" label="证书状态">
								</el-table-column>
								<el-table-column prop="issue_date" label="发证日期">
								</el-table-column>
							</el-table>
						</div>
						<!-- <div class="row" v-for="item in infox" @click="toInfo(item)">
                            {{item}}
                        </div> -->
					</div>
					<div class="" v-if="condition=='社会组织基本信息'">
						{{msg}}
					</div>
					<div class="meeting" v-if="condition=='工商照面'">
						<div class="row" style="display:flex">
							<div class="td" style="flex:1.5">法人代表</div>
							<div class="td" style="flex:3">{{list.operName}}</div>
							<div class="td" style="flex:1.5">经营状态</div>
							<div class="td" style="flex:3;border:none">{{list.new_status}}</div>
						</div>
						<div class="row" style="display:flex">
							<div class="td" style="flex:1.5">注册资本</div>
							<div class="td" style="flex:3">{{list.registCapi}}</div>
							<div class="td" style="flex:1.5">实缴资本</div>
							<div class="td" style="flex:3;border:none">{{list.actualCapi}}</div>
						</div>
						<div class="row" style="display:flex">
							<div class="td" style="flex:1.5">统一社会信用代码</div>
							<div class="td" style="flex:3">{{list.creditNo}}</div>
							<div class="td" style="flex:1.5">纳税人识别号</div>
							<div class="td" style="flex:3;border:none"></div>
						</div>
						<div class="row" style="display:flex">
							<div class="td" style="flex:1.5">工商注册号</div>
							<div class="td" style="flex:3">{{list.regNo}}</div>
							<div class="td" style="flex:1.5">组织机构代码</div>
							<div class="td" style="flex:3;border:none">{{list.orgNo}}</div>
						</div>
						<div class="row" style="display:flex">
							<div class="td" style="flex:1.5">进出口企业代码</div>
							<div class="td" style="flex:3"></div>
							<div class="td" style="flex:1.5">海关注册编码</div>
							<div class="td" style="flex:3;border:none"></div>
						</div>
						<div class="row" style="display:flex">
							<div class="td" style="flex:1.5">英文名</div>
							<div class="td" style="flex:3">{{list.format_name}}</div>
							<div class="td" style="flex:1.5">历史名称</div>
							<div class="td" style="flex:3;border:none">{{list.historyNames}}</div>
						</div>
						<div class="row" style="display:flex">
							<div class="td" style="flex:1.5">所属行业</div>
							<div class="td" style="flex:3"></div>
							<div class="td" style="flex:1.5">成立日期</div>
							<div class="td" style="flex:3;border:none">{{list.startDate}}</div>
						</div>
						<div class="row" style="display:flex">
							<div class="td" style="flex:1.5">企业类型</div>
							<div class="td" style="flex:3">{{list.econKind}}</div>
							<div class="td" style="flex:1.5">营业期限</div>
							<div class="td" style="flex:3;border:none">{{list.termEnd}}</div>
						</div>
						<div class="row" style="display:flex">
							<div class="td" style="flex:1.5">登记机关</div>
							<div class="td" style="flex:3">{{list.belongOrg}}</div>
							<div class="td" style="flex:1.5">核准日期</div>
							<div class="td" style="flex:3;border:none">{{list.checkDate}}</div>
						</div>
						<div class="row" style="display:flex">
							<div class="td" style="flex:1">注册地址</div>
							<div class="td" style="flex:5;border:none">{{list.address}}</div>
						</div>
						<div class="row" style="display:flex; border-bottom:none">
							<div class="td" style="flex:1">经营范围</div>
							<div class="td" style="flex:5;line-height:20px;border:none">{{list.scope}}</div>
						</div>
					</div>
					<div class="meeting" v-if="condition=='工商实时信息'">
						<div class="row" style="display:flex">
							<div class="td" style="flex:1.5">法人代表</div>
							<div class="td" style="flex:3">{{list.oper_name}}</div>
							<div class="td" style="flex:1.5">经营状态</div>
							<div class="td" style="flex:3;border:none">{{list.status}}</div>
						</div>
						<div class="row" style="display:flex">
							<div class="td" style="flex:1.5">注册资本</div>
							<div class="td" style="flex:3">{{list.regist_capi}}</div>
							<div class="td" style="flex:1.5">统一社会信用代码</div>
							<div class="td" style="flex:3;border:none">{{list.credit_no}}</div>
						</div>
						<div class="row" style="display:flex">
							<div class="td" style="flex:1.5">企业注册号</div>
							<div class="td" style="flex:3">{{list.reg_no}}</div>
							<div class="td" style="flex:1.5">组织机构代码</div>
							<div class="td" style="flex:3;border:none">{{list.org_no}}</div>
						</div>
						<div class="row" style="display:flex">
							<div class="td" style="flex:1.5">所属行业</div>
							<div class="td" style="flex:3" v-for="item in list.domains">{{item}}</div>
							<div class="td" style="flex:1.5">成立日期</div>
							<div class="td" style="flex:3;border:none">{{list.start_date}}</div>
						</div>
						<div class="row" style="display:flex">
							<div class="td" style="flex:1.5">企业类型</div>
							<div class="td" style="flex:3">{{list.econ_kind}}</div>
							<div class="td" style="flex:1.5">营业期限</div>
							<div class="td" style="flex:3;border:none">{{list.term_end}}</div>
						</div>
						<div class="row" style="display:flex">
							<div class="td" style="flex:1.5">登记机关</div>
							<div class="td" style="flex:3">{{list.belong_org}}</div>
							<div class="td" style="flex:1.5">核准日期</div>
							<div class="td" style="flex:3;border:none">{{list.check_date}}</div>
						</div>
						<div class="row" style="display:flex">
							<div class="td" style="flex:1">注册地址</div>
							<div class="td" style="flex:5;border:none">{{list.address}}</div>
						</div>
						<div class="row" style="display:flex; border-bottom:none">
							<div class="td" style="flex:1">经营范围</div>
							<div class="td" style="flex:5;border:none">{{list.scope}}</div>
						</div>
					</div>
					<div class="Trademark" v-if="condition=='商标列表'">
						<el-table :data="typeList.items" border style="width: 100%">
							<el-table-column fixed type="index" label="序号" width="150">
							</el-table-column>
							<el-table-column prop="" label="商标">
								<template slot-scope="scope">
									　　　　<img :src="scope.row.image_name" width="64" height="40" class="head_pic" />
									　　</template>
							</el-table-column>
							<el-table-column prop="company" label="企业名称">
							</el-table-column>
							<el-table-column prop="reg_number" label="注册号">
							</el-table-column>
							<el-table-column prop="apply_date" label="申请日期">
							</el-table-column>
							<el-table-column prop="type_name" label="商标类型">
							</el-table-column>
							<el-table-column fixed="right" label="操作" width="150">
								<template slot-scope="scope">
									<el-button @click="handleClick(scope.row)" type="text" size="small">详情</el-button>
								</template>
							</el-table-column>
						</el-table>
					</div>
					<div class="trade" v-if="condition=='商标详细'">
						<div class="box">
							<!-- <div class="top">
                                <div class="left">
                                    <img src="" alt="">
                                    <div class="text">
                                        <p>1</p>
                                        <p>2</p>
                                        <p>3</p>
                                    </div>
                                </div>
                                <div class="right">
                                    无效
                                </div>
                            </div> -->
							<!-- <div class="title-c">
                            <p>基本信息</p>
                            <p>商标生命周期</p>
                        </div> -->
							<!-- <div class="title">基本信息</div>
                            <div class="Information">
                                <div class="row" style="display:flex">
                                    <div class="td" style="flex:1.5">商标</div>
                                    <div class="td" style="flex:3"></div>
                                    <div class="td" style="flex:1.5">商标名称</div>
                                    <div class="td" style="flex:3;border:none"></div>
                                </div>
                                <div class="row" style="display:flex">
                                    <div class="td" style="flex:1.5">申请/注册号</div>
                                    <div class="td" style="flex:3"></div>
                                    <div class="td" style="flex:1.5">商标类型</div>
                                    <div class="td" style="flex:3;border:none"></div>
                                </div>
                                <div class="row" style="display:flex">
                                    <div class="td" style="flex:1.5">商标状态</div>
                                    <div class="td" style="flex:3"></div>
                                    <div class="td" style="flex:1.5">申请日</div>
                                    <div class="td" style="flex:3;border:none"></div>
                                </div>
                                <div class="row" style="display:flex">
                                    <div class="td" style="flex:1.5">申请人名称</div>
                                    <div class="td" style="flex:3"></div>
                                    <div class="td" style="flex:1.5">申请人地址</div>
                                    <div class="td" style="flex:3;border:none"></div>
                                </div>
                                <div class="row" style="display:flex">
                                    <div class="td" style="flex:1.5">商标共有人</div>
                                    <div class="td" style="flex:3"></div>
                                    <div class="td" style="flex:1.5">代理/办理机构</div>
                                    <div class="td" style="flex:3;border:none"></div>
                                </div>
                                <div class="row" style="display:flex">
                                    <div class="td" style="flex:1.5">初审公告期号</div>
                                    <div class="td" style="flex:3"></div>
                                    <div class="td" style="flex:1.5">初审公告日</div>
                                    <div class="td" style="flex:3;border:none"></div>
                                </div>
                                <div class="row" style="display:flex">
                                    <div class="td" style="flex:1.5">注册公告期号</div>
                                    <div class="td" style="flex:3"></div>
                                    <div class="td" style="flex:1.5">注册公告日</div>
                                    <div class="td" style="flex:3;border:none"></div>
                                </div>
                                <div class="row" style="display:flex">
                                    <div class="td" style="flex:1.5">专用权期限</div>
                                    <div class="td" style="flex:3"></div>
                                    <div class="td" style="flex:1.5"></div>
                                    <div class="td" style="flex:3;border:none"></div>
                                </div>
                                <div class="row" style="display:flex">
                                    <div class="td" style="flex:1.5">国际分类</div>
                                    <div class="td" style="flex:3"></div>
                                    <div class="td" style="flex:1.5"></div>
                                    <div class="td" style="flex:3;border:none"></div>
                                </div>
                                <div class="row" style="display:flex;border:none">
                                    <div class="td" style="flex:1">类似群/商品/服务</div>
                                    <div class="td" style="flex:5;border:none"></div>
                                </div>
                            </div>
                            <div class="title">国际申请优先日</div>
                            <div class="application">
                                <div class="row" style="display:flex">
                                    <div class="td" style="flex:1.5;background: #FAFAFA;">国际注册日</div>
                                    <div class="td" style="flex:3"></div>
                                    <div class="td" style="flex:1.5;background: #FAFAFA;">后期指定日</div>
                                    <div class="td" style="flex:3;border:none"></div>
                                </div>
                                <div class="row" style="display:flex;border:none">
                                    <div class="td" style="flex:1.5;background: #FAFAFA;">优先权日</div>
                                    <div class="td" style="flex:3"></div>
                                    <div class="td" style="flex:1.5"></div>
                                    <div class="td" style="flex:3;border:none"></div>
                                </div>
                            </div> -->
							<!-- <div class="title">商标生命周期</div>
                        <div class="Lifecycle">
                            <div class="row">
                                <div class="top-c"><span>1</span> 商标申请</div>
                                <div class="center">
                                    <p>申请日：2017-09-05</p>
                                    <p>事件：商标注册申请</p>
                                </div>
                            </div>
                        </div> -->
						</div>
						<div class="" style="text-align: center;">暂无数据</div>
					</div>
					<div class="Trademark" v-if="condition=='专利列表'">
						<el-table :data="typeList.items" border style="width: 100%">
							<el-table-column fixed type="index" label="序号">
							</el-table-column>
							<el-table-column prop="patent_name" label="作品名称">
							</el-table-column>
							<el-table-column prop="request_num" label="登记号">
							</el-table-column>
							<el-table-column prop="type_name" label="类别">
							</el-table-column>
							<el-table-column prop="outhor_date" label="首次发布日期">
							</el-table-column>
							<el-table-column prop="request_date" label="登记日期">
							</el-table-column>
							<el-table-column prop="last_status" label="状态">
							</el-table-column>
							<el-table-column prop="app_person" label="作者">
							</el-table-column>
							<!-- <el-table-column fixed="right" label="操作" width="150">
                            <template slot-scope="scope">
                                <el-button @click="handleClick(scope.row)" type="text" size="small">详情</el-button>
                            </template>
                        </el-table-column> -->
						</el-table>
					</div>
					<div class="Trademark" v-if="condition=='著作权'">
						<el-table :data="typeList.items" border style="width: 100%">
							<el-table-column fixed type="index" label="序号">
							</el-table-column>
							<el-table-column prop="name" label="软件名称">
							</el-table-column>
							<el-table-column prop="version" label="版本号">
							</el-table-column>
							<el-table-column prop="number" label="登记号">
							</el-table-column>
							<el-table-column prop="type_name" label="分类号">
							</el-table-column>
							<el-table-column prop="short_name" label="软件简称">
							</el-table-column>
							<el-table-column prop="first_date" label="登记批准日期">
							</el-table-column>
						</el-table>
					</div>
					<div class="Trademark" v-if="condition=='软件著作权'">
						<el-table :data="typeList.items" border style="width: 100%">
							<el-table-column fixed type="index" label="序号">
							</el-table-column>
							<el-table-column prop="name" label="软件著作名称">
							</el-table-column>
							<el-table-column prop="version" label="版本号">
							</el-table-column>
							<el-table-column prop="number" label="登记号">
							</el-table-column>
							<el-table-column prop="type_name" label="分类号">
							</el-table-column>
							<el-table-column prop="short_name" label="软件简称">
							</el-table-column>
							<el-table-column prop="first_date" label="登记批准日期">
							</el-table-column>
						</el-table>
					</div>
					<div class="Trademark" v-if="condition=='资质信息'">
						<el-table :data="typeList.items" border style="width: 100%">
							<el-table-column fixed type="index" label="序号">
							</el-table-column>
							<el-table-column prop="type" label="证书类型">
							</el-table-column>
							<el-table-column prop="num" label="证书编号">
							</el-table-column>
							<el-table-column prop="status" label="证书状态">
							</el-table-column>
							<el-table-column prop="issue_date" label="发证日期">
							</el-table-column>
						</el-table>
					</div>
					<div class="Trademark" v-if="condition=='工程资质资格'">
						<el-table :data="typeList.items" border style="width: 100%">
							<el-table-column fixed type="index" label="序号">
							</el-table-column>
							<el-table-column prop="lic_name" label="资质名称">
							</el-table-column>
							<el-table-column prop="category" label="资质类别">
							</el-table-column>
							<el-table-column prop="lic_no" label="证书编号">
							</el-table-column>
							<el-table-column prop="status" label="证书状态">
							</el-table-column>
							<el-table-column prop="validity_start" label="发证日期">
							</el-table-column>
							<el-table-column prop="lic_scope" label="资质范围">
							</el-table-column>
						</el-table>
					</div>
					<div class="Trademark" v-if="condition=='高新技术企业资质'">
						<el-table :data="typeList.items" border style="width: 100%">
							<el-table-column fixed type="index" label="序号">
							</el-table-column>
							<el-table-column prop="register_no" label="证书编号">
							</el-table-column>
							<el-table-column prop="state" label="证书状态">
							</el-table-column>
							<el-table-column prop="valid_start" label="发证日期">
							</el-table-column>
							<el-table-column prop="valid_end" label="截止日期">
							</el-table-column>
						</el-table>
					</div>
				</div>
			</div>
		</el-dialog>

	</div>
</template>

<script>
	export default {
		data() {
			return {
				detai: true,
				//获取价格
				paramKey: "",
				info: "",
				unfold: true,
				selectAllPay: false,
				// category: ['企业概况','私发诉讼']
				dialogVisible: false,
				condition: "",
				category: [{
						id: 0,
						title: "工商信息",
						child: [{
								id: 0,
								title: "工商照面",
								form: {
									工商照面: {
										keyword: "",
										skip: 0
									},
								},
							},
							{
								id: 1,
								title: "企业联系方式",
								form: {
									企业联系方式: {
										keyword: "",
										skip: 0
									},
								},
							},
							{
								id: 2,
								title: "工商实时信息",
								form: {
									工商实时信息: {
										keyword: "",
										skip: 0
									},
								},
							},
						],
					},
					{
						id: 1,
						title: "知识产权",
						child: [{
								id: 3,
								title: "商标列表",
								form: {
									商标列表: {
										name: "",
										skip: 0
									},
								},
							},
							{
								id: 4,
								title: "商标详细",
								form: {
									商标详细: {
										name: "",
										number: "",
										skip: 0
									},
								},
							},
							{
								id: 5,
								title: "专利列表",
								form: {
									专利列表: {
										name: "",
										skip: 0
									},
								},
							},
							// { id: 7, title: '专利详细' },
							{
								id: 8,
								title: "著作权",
								form: {
									著作权: {
										name: "",
										skip: 0
									},
								},
							},
							{
								id: 9,
								title: "软件著作权",
								form: {
									软件著作权: {
										name: "",
										skip: 0
									},
								},
							},
						],
					},
					{
						id: 2,
						title: "资质认证",
						child: [{
								id: 11,
								title: "资质信息",
								form: {
									资质信息: {
										name: "",
										skip: 0
									},
								},
							},
							{
								id: 12,
								title: "工程资质资格",
								form: {
									工程资质资格: {
										name: ""
									},
								},
							},
							{
								id: 10,
								title: "高新技术企业资质",
								form: {
									高新技术企业资质: {
										name: ""
									},
								},
							},
						],
					},
				],
				gsxxLabel: [
					"法定代表人",
					"注册资本",
					"统一社会信用代码",
					"工商注册号",
					"所属行业",
					"企业类型",
					"注册地址",
					"经营范围",
				],
				info: null,
				pwd: sessionStorage.getItem("pwd"),
				needMoney: "",
				infox: [],
				dialogtext: false, //小类别详细内容
				msg: "",
				type: "",
				searchType: "", //搜索类型
				list: [],
				title: "",
				typeList: [],
				lestName: [],
				typeName: []
			};
		},
		mounted() {
			this.info = this.$route.query.info;
			
			// if (sessionStorage.getItem('getAddressInfo1') != '') {
			// 	this.info.address = JSON.parse(sessionStorage.getItem('getAddressInfo1')).val.data.address
			// 	this.info.phone = JSON.parse(sessionStorage.getItem('getAddressInfo1')).val.data.phone
			// } else {
				// this.beforeSearch()
				this.getAddressInfo();
			// }

		},
		beforeDestroy() {

		},
		methods: {
			back() {
				this.$router.replace(`/blurry?key=${this.$route.query.key}&page=${this.$route.query.page}`);
				sessionStorage.setItem('address1', JSON.stringify(this.info.address))
				sessionStorage.setItem('phone1', JSON.stringify(this.info.phone))
			},
			//查看详情
			handleClick() {
				this.detai = !this.detai
			},
			//获取价格
			getMoney() {
				this.$util
					.post("/sys-param/list", {
						paramKey: this.paramKey,
					})
					.then((res) => {
						console.log(res);
						this.needMoney = res.data[0].paramValue;
						this.type = this.paramKey
					});
			},
			getStockInfo() {
				let form = {
					typeBase: "工商信息",
					typeLittle: {
						企业联系方式: {
							keyword: this.info.name,
						},
					},
				};
				this.$util.postJ("/dataSquare/advanced/search", form).then((res) => {
					this.info.address = res.data[0].val.data.address;
				});
			},
			beforeSearch(item, val,data){
				this.$util.postJ("/dataSquare/beforeSearch", data).then((res) => {
					if (!this.infox.includes(val.title) && !res.data) {
						this.dialogVisible = true
						this.getAddressInfo()
						this.getMoney()
					} else {
						// this.searchType = 3
						this.confrimPay()
					}
				});
				
			},
			getAddressInfo() {
				let form = {
					typeBase: "工商信息",
					typeLittle: {
						企业联系方式: {
							keyword: this.info.name,
						},
					},
					payPsd: this.pwd,
				};
				this.$util.postJ("/dataSquare/advanced/search", form).then((res) => {
					this.info.address = res.data[0].val.data.address;
					this.info.phone = res.data[0].val.data.telephone;
					sessionStorage.setItem('getAddressInfo1', JSON.stringify(res.data[0]))
				});
			},
			/**
			 *
			 * @param {*} item 父级的数据
			 * @param {*} val  子级的数据
			 */
			// 单个搜索
			check(item, val) {
				this.searchType = 0
				this.condition = val.title
				this.paramKey = item.title + '*' + val.title
				// 获取第一个兼职
				let sum = Object.keys(val.form[val.title])[0];
				// 赋值
				val.form[val.title][sum] = this.info.name;

				if (val.form[val.title].number == "") {
					delete val.form[val.title].name;
					val.form[val.title].number = this.info.reg_no;
				}
				this.data = {
					typeBase: item.title,
					typeLittle: val.form,
					payPsd: sessionStorage.getItem("pwd")
				};
				this.beforeSearch(item, val,this.data)
				
			},
			checkNum() {
				this.searchType = 2
				this.dialogVisible = true
				this.condition = "企业联系方式"
				let form = {
					// BASE
					typeBase: "工商信息",
					typeLittle: {
						企业联系方式: {
							keyword: this.info.name,
						},
					},
					payPsd: this.pwd,
				};
				this.$util
					.post("/sys-param/list", {
						paramKey: "工商信息*企业联系方式",
					})
					.then((res) => {
						console.log(res);
						this.needMoney = res.data[0].paramValue;
						this.type = this.paramKey
					});
				// this.$util.postJ("/dataSquare/advanced/search", form).then((res) => {
				//     console.log(res);
				//     if (res.code == 200) {
				//         this.info.phoneShow = true;
				//         this.info.phone = res.data[0].val.data.telephone;
				//     }
				// });
			},
			//点击打开弹窗并展示信息(全选)
			selectAll(condition, val) {
				console.log(val);
				this.searchType = 1
				this.paramKey = val.title + "*全选"
				console.log(val.title + "*全选");
				let str = ''
				val.child.forEach(ele => {
					str += ele.title + ' '
				})
				this.condition = str
				console.log(this.condition);
				if (!this.lestName.includes(str)) {
					this.dialogVisible = true;
				} else {
					this.dialogtext = true
				}

				this.getMoney()
				this.getAddressInfo()
				// let typeLittle = []
				let obj = {}
				val.child.map((el, l) => {
					el.form[el.title].name = this.info.name;
					el.form[el.title].keyword = this.info.name
					// typeLittle.push(el.form)
					if (el.form[el.title].number == "") {
						delete el.form[el.title].name;
						el.form[el.title].number = this.info.name;
					}
					obj[el.title] = el.form[el.title]
					console.log(obj);
				})
				this.data = {
					typeBase: val.title,
					typeLittle: obj,
					payPsd: sessionStorage.getItem("pwd")
				};
				console.log(this.data);
			},
			handleDialogClose(done) {
				this.$confirm("确认关闭？")
					.then((_) => {
						done();
					})
					.catch((_) => {});
			},
			confrimPay() {
				if (this.searchType == 0) {
					this.$util.postJ("/dataSquare/advanced/search", this.data).then((res) => {
						this.selectAllPay = true;
						this.condition = res.data[0].key
						this.dialogVisible = false;
						this.list = res.data[0].val.data
						this.typeList = res.data[0].val.data
						this.msg = res.data[0].val.message
						this.dialogtext = true
						this.infox.push(this.condition)
						sessionStorage.setItem("infox", JSON.stringify(this.infox))
						sessionStorage.setItem(this.condition, JSON.stringify(this.list))
					});
				} else if (this.searchType == 1) {
					this.$util.postJ("/dataSquare/advanced/search", this.data).then((res) => {
						this.selectAllPay = true;
						this.dialogVisible = false;
						this.list = res.data[0].val.data
						this.typeList = res.data[0].val.data
						this.lestName.push(this.condition)
						this.dialogtext = true
						this.infox.push(...this.condition.split(" "))
						this.typeName = this.condition.split(" ")
						sessionStorage.setItem("infox", JSON.stringify(this.infox))
						res.data.forEach(el => {
							console.log(el);
							sessionStorage.setItem(el.key, JSON.stringify(el.val.data))
						})
					});
				} else {
					let form = {
						// BASE
						typeBase: "工商信息",
						typeLittle: {
							企业联系方式: {
								keyword: this.info.name,
							},
						},
						payPsd: this.pwd,
					};
					this.$util.postJ("/dataSquare/advanced/search", form).then((res) => {
						console.log(res);
						if (res.code == 200) {
							this.info.phoneShow = true;
							this.dialogVisible = false;
							this.info.phone = res.data[0].val.data.telephone;
							this.infox.push(res.data[0].key)
						}
					});

				}
				// 确认支付展示该公司联系方式
				// this.dialogtext = true
				// this.dialogVisible = false;
				// this.unfold = false;
				// this.selectAllPay = true;
				//单个搜索
			},
		},
	};
</script>

<style lang="scss" scoped>
	.pagination {
		width: 1200px;
		margin: 70px auto;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	/deep/.el-pager li {
		width: 40px;
		height: 40px;
		line-height: 40px;
		border-radius: 4px !important;
		border: 1px solid #2ea7e0;
		margin: 0 3px;
		color: #2ea7e0 !important;
	}

	/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
		color: #fff !important;
	}

	/deep/.el-pagination .btn-next,
	/deep/.el-pagination .btn-prev {
		width: 40px;
		height: 40px;
		line-height: 40px;
		border-radius: 4px !important;
		// border: 1px solid #2ea7e0;
		background: transparent;
		margin: 0 3px;
	}

	/deep/.el-pagination .btn-next .el-icon,
	/deep/.el-pagination .btn-prev .el-icon {
		font-size: 24px;
		color: #2ea7e0;
	}

	/deep/.el-pager li.active+li {
		border: 1px solid #2ea7e0;
	}

	.total {
		color: #2ea7e0;
		font-weight: bold;
		font-size: 16px;
	}

	.jump {
		display: flex;
		align-items: center;
		font-size: 16px;
		margin: 0 20px 0 40px;
		color: #2ea7e0;

		input {
			width: 40px;
			height: 40px;
			border: 1px solid #0f6c97;
			font-size: 14px;
			color: #2ea7e0;
			outline: none;
			margin: 0 10px;
			border-radius: 4px;
		}
	}

	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}

	input[type="number"] {
		-moz-appearance: textfield;
	}

	.table {
		div {
			box-sizing: border-box;
		}

		width: 100%;
		// margin-bottom: 30px;

		.tbody {
			width: 1200px;
			height: 581px;
			border: 1px solid #eeeeee;
			border-bottom: 0px;

			.tr {
				display: flex;
				height: 62px;
				width: 100%;
				border-bottom: 1px solid #eeeeee;

				&:last-child {
					height: 148px;
				}

				.td {
					height: 100%;
					padding-left: 20px;
					border-right: 1px solid #eeeeee;
					// text-align: center;
					line-height: 62px;

					&:nth-child(odd) {
						width: 168px;
					}

					&:nth-child(even) {
						width: 800px;
					}

					&:last-child {
						border-right: 0;
					}
				}
			}
		}
	}

	.table2 {
		div {
			box-sizing: border-box;
		}

		width: 100%;
		// margin-bottom: 30px;

		.tbody {
			width: 1200px;
			height: 186px;
			border: 1px solid #eeeeee;
			border-bottom: 0px;

			.tr {
				display: flex;
				height: 62px;
				width: 100%;
				border-bottom: 1px solid #eeeeee;

				.td {
					height: 100%;
					padding-left: 20px;
					border-right: 1px solid #eeeeee;
					// text-align: center;
					line-height: 62px;
					flex: 1;

					&:last-child {
						border-right: 0;
					}
				}
			}

			&:last-child {
				.td {
					text-align: center;
				}
			}
		}
	}

	/deep/.el-dialog__body {
		padding: 0 20px;
	}

	.dialog-title {
		display: flex;
		align-items: center;
		font-size: 22px;
		font-weight: 400;
		color: #3d3d3d;
		margin-bottom: 10px;
	}

	.dialog-body {
		font-size: 16px;
		font-weight: 400;
		padding-top: 30px;
		padding-bottom: 64px;

		.title {
			padding: 30px 0 20px 0;
			font-size: 16px;
			font-family: Microsoft YaHei-Regular, Microsoft YaHei;
			font-weight: 400;
			color: #222222;
		}

		.meeting {
			border: 1px solid #eeeeee;

			.row {
				font-size: 16px;
				font-family: Microsoft YaHei-Regular, Microsoft YaHei;
				font-weight: 400;
				color: #666666;
				line-height: 62px;
				border-bottom: 1px solid #eeeeee;

				.td {
					padding-left: 10px;
					border-right: 1px solid #eeeeee;
				}
			}
		}

		.trade {
			.title {
				font-size: 16px;
				font-family: Microsoft YaHei-Regular, Microsoft YaHei;
				font-weight: 400;
				color: #222222;
			}

			.top {
				border: 1px solid #eeeeee;
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding-left: 20px;
				padding-right: 20px;

				.left {
					font-size: 20px;
					font-family: Microsoft YaHei-Regular, Microsoft YaHei;
					font-weight: 400;
					color: #222222;
					padding-top: 20px;
					padding-bottom: 20px;
					display: flex;
					align-items: center;

					img {
						width: 98px;
						height: 98px;
					}

					p {
						padding-left: 20px;
					}
				}

				.right {
					transform: rotate(-30deg);
					text-align: center;
					font-size: 23px;
					font-family: FandolSong-Bold, FandolSong;
					font-weight: bold;
					color: #999999;
					line-height: 52px;
					width: 80px;
					height: 52px;
					border-radius: 0px 0px 0px 0px;
					opacity: 1;
					border: 2px solid #bbbbbb;
				}
			}

			.title-c {
				margin-top: 20px;
				border: 1px solid #eeeeee;
				display: flex;
				font-size: 14px;
				font-family: Microsoft YaHei-Bold, Microsoft YaHei;
				font-weight: bold;
				color: #222222;

				p {
					padding: 21px 30px;
					color: #2ea7e0;
				}

				p:nth-child(2) {
					border-left: 1px solid #eeeeee;
				}
			}

			.Information {
				border: 1px solid #eeeeee;
				font-size: 16px;
				font-family: Microsoft YaHei-Regular, Microsoft YaHei;
				font-weight: 400;
				color: #666666;

				.row {
					font-size: 16px;
					font-family: Microsoft YaHei-Regular, Microsoft YaHei;
					font-weight: 400;
					color: #666666;
					line-height: 62px;
					border-bottom: 1px solid #eeeeee;

					.td {
						padding-left: 10px;
						border-right: 1px solid #eeeeee;
					}
				}
			}

			.application {
				border: 1px solid #eeeeee;
				font-size: 16px;
				font-family: Microsoft YaHei-Regular, Microsoft YaHei;
				font-weight: 400;
				color: #666666;

				.row {
					font-size: 16px;
					font-family: Microsoft YaHei-Regular, Microsoft YaHei;
					font-weight: 400;
					color: #666666;
					line-height: 62px;
					border-bottom: 1px solid #eeeeee;

					.td {
						padding-left: 10px;
						border-right: 1px solid #eeeeee;
					}
				}
			}

			.Lifecycle {
				border: 1px solid #eeeeee;

				.row {}
			}
		}

		// .row {
		//     margin-top: 20px;
		//     padding-left: 20px;
		//     // width: 100%;
		//     // height: 61px;
		//     border-radius: 0px 0px 0px 0px;
		//     opacity: 1;
		//     border: 1px solid #eeeeee;
		// }

		label {
			margin-right: 20px;
		}
	}

	.dialog-btn {
		width: 247px;
		height: 60px;
		background: #e40012;
		font-size: 18px;
		font-weight: 400;
		color: #ffffff;
	}

	.dialog-footer {
		display: flex;
		justify-content: space-between;
		padding: 0 293px;
		margin-bottom: 80px;
	}

	.header {
		box-sizing: border-box;
		display: flex;
		border: 1px solid #eeeeee;
		padding: 20px;

		.img {
			width: 198px;
			height: 212px;
			background-color: #4480d8;
			text-align: center;
			line-height: 212px;
			font-size: 60px;
			color: #fff;
			// font-weight: bold;
			margin-right: 20px;
		}

		.right {
			height: 212px;
			flex: 1;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}

		.info {
			box-sizing: border-box;
			display: flex;
			flex-wrap: wrap;
			width: 942px;
			height: 166px;
			background: #f8f8f8;
			padding: 20px;
			font-size: 16px;
			font-weight: 400;
			color: #666666;

			div:nth-child(odd) {
				width: 60%;
			}

			div:nth-child(even) {
				width: 40%;
			}
		}
	}

	.mian {
		margin-top: 48px;

		.tit {
			font-size: 20px;
			font-weight: 400;
			color: #222222;
			height: 46px;
			display: flex;
			justify-content: space-between;
			border-bottom: 1px solid #eeeeee;

			i {
				margin-top: 5px;
			}
		}

		.item {
			.title {
				font-size: 18px;
				font-weight: 400;
				color: #222222;
				height: 50px;
				background: #f6f6f6;
				line-height: 50px;

				label {
					margin: 0 30px 0 20px;
				}

				span {
					color: #2ea7e0;
					cursor: pointer;
				}
			}

			.content {
				display: flex;
				flex-wrap: wrap;
				// height: 163px;
				padding: 30px 0;

				span {
					cursor: pointer;
					margin: 10px 22px;
				}
			}
		}
	}
</style>
